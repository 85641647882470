import React from 'react'
import { Link } from 'gatsby'
import { format } from 'date-fns'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { getConversion } from '../../api/images';
const FirstBlogCard = ({ post }) => {
    const { slug, title, published_at, summary, body, media, link } = post
    var featured_images = media.filter(media => media.collection_name === 'featured_images')

    return (
        <div className='col-12'>
            <div className='single-blog-post bg-fffbf5'>
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <Link to={link ? link : `/blog-details?slug=${slug}`} target={link ? '_blank' : '_self'}>
                            {media.length > 0 ?
                                <Swiper
                                    loop={true}
                                    autoplay={{
                                        delay: 3500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={media.length > 1 ? [Autoplay, Pagination] : []}
                                    className="mySwiper2"
                                >
                                    {media.slice(0, 5).map((item, index) => {
                                        return (
                                            <SwiperSlide key={`image-swiper-${index}`}>
                                                <img src={getConversion(item)} alt={title} />
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                                : null
                            }
                        </Link>
                    </div>
                    <div className='col-12 col-md-6'>
                        <div className='post-content'>
                            <ul className='post-meta d-flex justify-content-between align-items-center'>
                                <li>
                                </li>
                                <li>
                                    <i className='flaticon-calendar'></i>  {format(new Date(published_at), 'dd/MM/yyyy HH:mm')}
                                </li>
                            </ul>
                            <h5>
                                <Link to={link ? link : `/blog-details?slug=${slug}`} target={link ? '_blank' : '_self'}>
                                    {title}
                                </Link>
                            </h5>
                            <p className='mt-3'>{summary}</p>
                            <div dangerouslySetInnerHTML={{ __html: body?.substr(0, 200) + '...' }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FirstBlogCard