import React from 'react'
import { Link } from 'gatsby'
import { format } from 'date-fns'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { getConversion } from '../../api/images';

const BlogCard = ({ post }) => {
    const { slug, title, published_at, summary, media, link } = post
    var featured_images = media.filter(media => media.collection_name === 'featured_images')
    return (
        <div className='mb-3 col-lg-4 col-md-6'>
            <div className='single-blog-post bg-fffbf5 h-100'>
                <div className='post-image'>
                    <Link to={link ? link :`/blog-details?slug=${slug}`} target={link ? '_blank' : '_self'}>
                    {media.length > 0 ?
                                <Swiper
                                    loop={true}
                                    autoplay={{
                                        delay: 3500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={media.length > 1 ? [Autoplay, Pagination] : []}
                                    className="mySwiper2"
                                >
                                    {media.slice(0, 5).map((item, index) => {
                                        return (
                                            <SwiperSlide key={`image-swiper-${index}`}>
                                                <img src={getConversion(item)} alt={title} />
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                                : null
                            }
                    </Link>
                </div>
                <div className='post-content'>
                    <ul className='post-meta d-flex justify-content-between align-items-center'>
                        <li>
                        </li>
                        <li>
                            <i className='flaticon-calendar'></i> {format(new Date(published_at), 'dd/MM/yyyy HH:mm')}
                        </li>
                    </ul>
                    <h5>
                        <Link to={link ? link :`/blog-details?slug=${slug}`} target={link ? '_blank' : '_self'}>
                            {title}
                        </Link>
                    </h5>
                    <p className='mt-3'>{summary && summary.substr(0, 100) + '...'}</p>
                </div>
            </div>
        </div>
    )
}

export default BlogCard